export function merge(target, obj) {
  for (let key in obj) {
    if (!target[key]) target[key] = {};

    if (Object.prototype.toString.call(obj[key]) === '[object Object]') target[key] = merge(target[key], obj[key]);
    else target[key] = obj[key];
  }
  return target;
}

export function dataConfig(options = {}) {
  const def = {
    'size': 'mini',
    'type': 'datetimerange',
    'range-separator': '-',
    'start-placeholder': '开始日期',
    'end-placeholder': '结束日期',
    'value-format': 'timestamp'
  };
  return merge(def, options);
}

// 示例: <el-input v-model="forms.contentTitle" @input="forms.aa = inputNumber(forms.aa, 2)"></el-input>
export function inputNumber(str, limit = 2) {
  const patt = limit ? /[^0-9\.]/g : /[^0-9]/g;
  const res = str.replace(patt,'');
  const i = res.indexOf('.');
  if (i > 0) return res.substring(0, i + limit + 1);
  else return +res ? +res : '';
}

export function lazy(fn, delay = 300) {
  if (this.timer) clearTimeout(this.timer);
  this.timer = setTimeout(() => {
    fn.call(this);
  }, delay);
}

export function clone(obj) {
  if (typeof obj === 'object' && obj !== null) {
    const res = Array.isArray(obj) ? [] : {};
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) res[i] = clone(obj[i]);
    } else {
      for (let key in obj) res[key] = clone(obj[key]);
    }
    return res;
  } else {
    return obj;
  }
}

// 获取省市区 id传0获取省
export function getAreas(vm, id = 0) {
  return new Promise(async resolve => {
    const res = await vm.$axios({
      method: 'get',
      url: vm.$api.commonNew.getChildRegion,
      params: { regionCode: id }
    });

    if (res.code === 0) resolve(res.data);
  })
}

export function tableAttrs(vm, options = {}) {
  const _default = {
    usePagAttrs: {
      'total': 0,
      'current-page': 1,
      'page-size': 20,
      'page-sizes': [10, 20, 50, 100],
      'background': false,
      'layout': 'total, sizes, prev, pager, next, jumper',
      'pageWidth': 17
    },
    currentChange() {
      vm.getData();
    },
    sizeChange() {
      vm.getData();
    }
  };
  const opt = merge(_default, options);
  let current = opt.usePagAttrs['current-page'];

  return {
    list: [],
    load: false,
    useTableAttrs: opt.useTableAttrs || {},
    usePagAttrs: opt.usePagAttrs,
    pagEvents: {
      'current-change'(index) {
        if (current != index) {
          if (opt.tableKey) vm[opt.tableKey].usePagAttrs['current-page'] = index;
          else vm.usePagAttrs['current-page'] = index;
          opt.currentChange();
          current = index;
        }
      },
      'size-change'(size) {
        if (opt.tableKey) {
          vm[opt.tableKey].usePagAttrs['current-page'] = 1;
          vm[opt.tableKey].usePagAttrs['page-size'] = size;
        } else {
          vm.usePagAttrs['current-page'] = 1;
          vm.usePagAttrs['page-size'] = size;
        }

        opt.sizeChange();
      }
    }
  };
}

export function msgboxRequest(vm, options = {}) {
  const def = {
    title: '提示',
    dangerouslyUseHTMLString: true,
    showCancelButton: true,
    beforeClose: async(action, instance, done) => {
      if (action == 'confirm') {
        instance.confirmButtonLoading = true;
        try {
          const res = await vm.$axios(options.requestOptions);
          instance.confirmButtonLoading = false;
          if (res.code === 200) {
            vm.$message.success(options.successMsg || '操作成功');
            done();
            vm.getData();
          } else {
            vm.$message.error(res.msg + '');
          }
        } catch (e) {
          instance.confirmButtonLoading = false;
        }
      } else {
        done();
      }
    }
  };
  vm.$msgbox(merge(def, options)).catch(err => console.log('err', err));
}

export function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ab], { type: mimeString });
}

// 上传图片接口
export async function uploadOssApi(data, onUploadProgress) {
  const params = new FormData();
  params.append('multipartFile', data);

  const options = {
    url: this.$api.nb_common.newUpload,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: params
  };

  if (onUploadProgress) options.onUploadProgress = onUploadProgress;
  return this.$axios(options);
}

export const validFn = {
  reg: {
    trim: /^\s+|\s+$/g,
    phone: /^((\+?86)|(\(\+86\)))?(1[1-9][0-9]{9})$/
  },
  validEmpty(rule, value, callback) {
    if (!value || !value.replace(/^\s+|\s+$/g, '')) callback(new Error(rule.msg));
    else callback();
  },
  validPhone(rule, value, callback) {
    if (!validFn.reg.phone.test(value)) callback(new Error(rule.msg));
    else callback();
  }
};